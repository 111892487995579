import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const StoryPage = () => {
  return (
    <Layout>
      <SEO
        title={"How empathy changes UX design"}
        description={"The meeting place for people, products, and great design"}
      />
      <section className="slide whiteSlide kenBurns">
        <div className="content">
          <div className="container">
            <div>
              <div className="story-heading">
                <div className="fix-7-12">
                  <h2 className="ae-1 normal margin-bottom-2">
                    How empathy changes everything about UX Design.
                  </h2>
                </div>
                <div className="ae-1 story-close-btn">
                  <a href="/learn">
                    <img src="/assets/img/cancel-x-icon.png" />
                  </a>
                </div>
              </div>
              <div className="ae-2 story-image">
                <img alt="" src="/assets/img/story-image-1.png" />
              </div>
              <div className="ae-2 story-content">
                <div className="story-text">
                  <p>
                    If you’re looking for the latest in wireless headphones for
                    your enjoyment and private listening, whether to TV, stereo,
                    home entertainment such as theater, iTunes or Ipod, you
                    might do well to look at the highly recommended Sennheiser
                    RS 130 wireless headphones. <br />
                    These state of the art wireless headphones provide an
                    advanced communication via FM radio frequency. The result is
                    clear sound with no background noise or static and nearly
                    500 feet of free movement. The headphones have terrific
                    surround sound effects and weigh less than ten ounces. They
                    are ergonomically designed to provide hours of comfort.{" "}
                    <br />
                    The features of the Sennheiser RS130 wireless headphones
                    include a base station for the headphones that is attractive
                    and stores the headphones whre they are not in use and also
                    charges the batteries. Batteries are NiMH type, and their
                    single charge keeps them going for 22 hours of your
                    listening enjoyment. The wireless headphones base station
                    has a 900 MHz high performance transmission, also wireless,
                    that delivers a playback that is very impressive in its high
                    fidelity capability. Its transmitters use Sennheiser’s
                    customized system of intelligent automated tuning. This
                    seeks and then chooses the best of three channels from base
                    to headset that are available for delivery of sound to the
                    wireless headphones.
                  </p>
                  <div className="story-quote light-text">
                    <q>
                      We create simple but powerful experiences because
                      greatness is simple. We see with the eyes of our
                      customers, listen with their ears and feel with their
                      hearts.
                    </q>
                  </div>
                  <p>
                    The features of the Sennheiser RS130 wireless headphones
                    include a base station for the headphones that is attractive
                    and stores the headphones whre they are not in use and also
                    charges the batteries. Batteries are NiMH type, and their
                    single charge keeps them going for 22 hours of your
                    listening enjoyment. The wireless headphones base station
                    has a 900 MHz high performance transmission, also wireless,
                    that delivers a playback that is very impressive in its high
                    fidelity capability. Its transmitters use Sennheiser’s
                    customized system of intelligent automated tuning. This
                    seeks and then chooses the best of three channels from base
                    to headset that are available for delivery of sound to the
                    wireless headphones.
                  </p>
                  <div className="story-controls">
                    <div className="story-author-box">
                      <span className="author-image">
                        <img
                          alt="Author"
                          src="/assets/img/story-author-1.png"
                        />
                      </span>
                      <div className="author-detail">
                        <div className="author-name">Mitchelle Ovie</div>
                        <div className="author-position">
                          Product Lead, Antel Studio
                        </div>
                      </div>
                    </div>
                    <a className="story-share" href="#">
                      <span>Share</span>
                      <img src="/assets/img/story-share.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default StoryPage;
